import { defineStore } from 'pinia';
import type { Context } from '@nuxt/types';
import type Vue from 'vue';
import { useEventStore } from './events';
import { useOnboardingStore } from '@/store/onboarding';

export const useAuthActionsStore = defineStore('authActions', () => {
  const { getNotifications } = useOnboardingStore();

  // FIXME: Поправить типы
  async function executeLoginActions (this: Vue, store: Context['store']): Promise<void> {
    const { user } = store.state?.auth || {};
    this.$sentry.setUser({ username: `${ user.name || '' } ${ user.surname || '' }` });

    if (!store.state.events.qrCodeData) {
      await useEventStore().loadQRCode();
    }

    if (process.client) {
      await getNotifications();
    }
  }

  // FIXME: Поправить типы
  async function executeLogoutActions (this: Vue, store: Context['store']): Promise<void> {
    this.$sentry.setUser(null);
    useEventStore().resetQRCode();
  }

  return {
    executeLoginActions,
    executeLogoutActions
  };
});
