import { defineStore } from 'pinia';

import type { StateChanger } from 'vue-infinite-loading';
import { useGlobalStore } from '../global';
import type {
  Agreement,
  PreparedAgreement
} from '@/types/Agreements';
import type { PageInfo } from '@/types/PageInfo';

interface AgreementsResponse {
  count: number;
  'page_info': PageInfo;
  result: Array<Agreement>;
}

interface State {
  additionalAgreements: Array<PreparedAgreement>;
  count: number;
  pageInfo: PageInfo | object;
}

export const useAdditionalAgreementsStore = defineStore('additionalAgreements', {
  state: (): State => ({
    additionalAgreements: [],
    count: 0,
    pageInfo: {}
  }),

  getters: {
    getHeadingList (): Array<object> {
      return [
        {
          movable: false,
          label: 'Дата создания',
          hint: ''
        },
        {
          movable: false,
          label: 'Проект',
          hint: ''
        }
      ];
    }
  },

  actions: {
    async getAdditionalAgreements (params: object): Promise<void> {
      try {
        const { role } = useGlobalStore();
        const {
          list
        } = this.$api.additional;
        const url = list(role);

        const {
          data: {
            count,
            result,
            page_info: pageInfo
          }
        } = await this.$axios.get<AgreementsResponse>(url, {
          params
        });

        this.additionalAgreements = preparedAgreements(result);
        this.count = count;
        this.pageInfo = pageInfo;
      } catch (error: unknown) {
        console.log('🚀 ~ file: additionalAgreements/index.ts ~ getAdditionalAgreements ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async nextPage (
      {
        page,
        infiniteState
      }: {
      page: PageInfo;
      infiniteState: StateChanger;
    }): Promise<void> {
      try {
        if (!page?.next_page) {
          return;
        }

        const {
          data: {
            result,
            page_info: pageInfo
          }
        } = await this.$axios.get<AgreementsResponse>(page?.next_page);

        if (!pageInfo) {
          throw new Error('page info not received');
        }

        if (result?.length) {
          this.pageInfo = pageInfo;
          this.additionalAgreements = [
            ...this.additionalAgreements,
            ...preparedAgreements(result)
          ];

          if (!pageInfo?.next_page) {
            infiniteState.complete();
          } else {
            infiniteState.loaded();
          }
        } else {
          infiniteState.loaded();
        }
      } catch (error: unknown) {
        console.log('🚀 ~ file: additionalAgreements/index.ts ~ nextPage ~ error', error);
        infiniteState.complete();
        this.$sentry.captureException(error);
      }
    }
  }
});

function preparedAgreements (items: Array<Agreement>): Array<PreparedAgreement> {
  return items.map((item) => {
    const {
      slug,
      files
    } = item.files[0] ?? {};

    return {
      ...item,
      documentType: slug,
      files
    };
  });
}
