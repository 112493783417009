import { defineStore } from 'pinia';

import { useGlobalStore } from './global';
import type {
  Deal
} from '@/types/Deal.js';

interface PaymentMethod {
  slug: string;
  isByDev?: boolean; // флаг для субсидированной ипотеки
  mortgageProgramName?: string; // ипотечное предложение
  calculatorOptions?: string; // опции ипотечного калькулятора (json)
  /* calculatorOptions не реализован, т.к. на брокере закрыты доп опции */

}

interface DealAction {
  type: string;
  title: string;
  hint: string;
  text: string;
  button: {
    label: string;
    action: {
      type: string;
      id: string;
    };
  };
}

interface State {
  deal: Deal | object;
  dealActions: Array<DealAction>;
  paymentMethod?: PaymentMethod;
}

export const useDealStore = defineStore('deal', {
  state: (): State => ({
    deal: {},
    dealActions: [
      {
        type: 'start',
        title: 'требуется сформировать пакет документов',
        hint: 'Пройдите опросник и загрузите пакет документов для начала оформления сделки',
        text: '',
        button: {
          label: 'Начать оформление',
          action: {
            type: 'start-quiz',
            id: 'package_of_documents'
          }
        }
      }
    ],
    paymentMethod: undefined
  }),

  actions: {
    async getDeal (id: number): Promise<void> {
      try {
        const { role } = useGlobalStore();
        const {
          booking
        } = this.$api.account.dependentRole;
        const url = booking('users', role, id);

        const {
          data
        } = await this.$axios.get<Deal>(url);

        this.deal = data;
      } catch (error) {
        this.deal = {};
        console.error('🚀 ~ file: deals.js ~ getDeal ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    async setActionCheckStatus (
      payload: {
        id: number;
        slug: string;
      }
    ): Promise<void> {
      try {
        const {
          id,
          slug
        } = payload;
        const url = `/api/task_management/task_instance/${ id }`;
        const params = {
          comment: '',
          task_amocrmid: '',
          slug
        };

        await this.$axios.patch(url, params);
      } catch (error) {
        console.log('🚀 ~ file: deal.js ~ setActionCheckStatus ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    async extendClientDeal (id: number): Promise<void> {
      try {
        const url = `api/booking/extend_client_fixation/${ id }`;

        await this.$axios.patch(url);
      } catch (error) {
        console.log('🚀 ~ file: deal.js ~ getActsByDeal ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    setPaymentMethod (payload: PaymentMethod): void {
      this.paymentMethod = payload;
    }
  }
});
