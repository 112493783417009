import { defineStore } from 'pinia';
import { ref } from 'vue';
import { set } from 'vue-demi';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useContext } from '@nuxtjs/composition-api';

import DOMPurify from 'isomorphic-dompurify';
import { templatesApiConfig } from '@/config/api/templates';

interface Template {
  title?: string;
  text: string;
}

type TemplateObj = Record<string, Template | undefined>;

type Slug = Record<string, string>;
const slug: Slug = {
  AGENT_MODAL: 'agent_fire_modal',
  AGENT_JOIN: 'agent_join_agency',
  REPRES_MODAL: 'repres_fire_agent_modal',
  REPRES_SUCCESS: 'repres_fire_agent_success',
  EVENT_TEMPLATE: 'event_template',
  BOOKING_PROJECT: 'choice_residential_complex_description',
  BOOKING_SUCCESS: 'success_paid_booking_description',
  main_page_main_section: 'main_page_main_section',
  main_page_offer_section: 'main_page_offer_section',
  main_page_partner_section: 'main_page_partner_section',
  main_page_sell_online_section: 'main_page_sell_online_section',
  main_page_manager_section_title: 'main_page_manager_section_title',
  main_page_manager_section: 'main_page_manager_section'
} as const;

interface getTemplatePayload {
  slug: string;
  agentId?: number;
}

export const useTemplatesStore = defineStore('templates', () => {
  const template = ref<TemplateObj>({});

  function getSlug (key: string): string {
    return slug[key];
  }

  function getTemplateBySlug (slug: string): Template | undefined {
    return template.value[slug];
  }

  const { $axios } = useContext();
  async function loadTemplate ({ slug, agentId }: getTemplatePayload): Promise<void> {
    if (template.value?.[slug]) {
      return;
    }

    const url = templatesApiConfig.getTemplateBySlug(slug);
    const { data } = await $axios.get<Template>(url, {
      params: {
        agent_id: agentId
      }
    });

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const templateKey = key as keyof Template;

        if (data[templateKey]) {
          data[templateKey] = DOMPurify
            .sanitize(data[templateKey] as string, { USE_PROFILES: { html: true } });
        }
      }
    }
    set(template.value, slug, data);
  }

  async function loadSmsText (projectId: [string, number]) {
    const url = templatesApiConfig.getAssignSmsText;
    const { data } = await $axios.get<Template>(url, {
      params: {
        project_id: projectId
      }
    });
    set(template.value, 'SMS_TEXT', data);
  }

  function resetTemplate (slug: string) {
    set(template.value, slug, undefined);
  }

  const decoratedRethrow = decorateApiActions({
    loadTemplate,
    loadSmsText
  }, 'templates', true);

  const decoratedNoRethrow = decorateApiActions({
    resetTemplate
  }, 'templates');

  return {
    template,
    getSlug,
    getTemplateBySlug,
    ...decoratedRethrow,
    ...decoratedNoRethrow
  };
});
