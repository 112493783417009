const templatesApi = {
  template: {
    getTemplateBySlug: (slug: string) => `/api/templates/${ slug }`,
    getAssignSmsText: '/api/templates/assign/sms_text'
  }
};

export default templatesApi;

export const templatesApiConfig = templatesApi.template;
