import { defineStore } from 'pinia';
import {
  ref,
  computed
} from 'vue';
import { useChessboardStore } from '@strana-artw/chessboard';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useApiClient } from '@/plugins/axios';
import { useBuildingsStore } from '@/store/buildings';
import { bookingApiConfig } from '@/config/api/booking';
import { useIsomorphicRoute } from '@/plugins/app-context';

interface CreateObjectPayload {
  propertyId: string;
  type: string;
  bookingTypeId: number;
}

interface RootGetters {
  role: string;
}

interface CreateBookingByPinningPayload {
    userId: number;
    activeProject: number;
}

interface CreateFreeBookingByPinningPayload {
  profitbaseId: number;
  bookingId: number;
  commentText: string;
  categoriesBookingIds: Array<number>;
}

interface BookRealtyPayload {
  propertyId: number;
  paymentMethodSlug: string;
  mortgageType: boolean;
  mortgageProgramName: string;
  calculatorOptions: string;
  bookingId: number;
  bookingTypeId: number;
}

type ISODateString = string;

interface BookRealtyResponse {
  id: number;
  active: boolean;
  propertyId: number;
  expires: ISODateString;
  created: ISODateString;
}

interface BookingResult {
  data: BookRealtyResponse;
  status: string;
}

export const STAGE_STEPS = {
  RealtyDataStep: 'RealtyDataStep',
  BookingParamsStep: 'BookingParamsStep',
  BookingResultStep: 'BookingResultStep'
};

const steps = [
  {
    component: 'SelectedDealsBookingProjects',
    label: 'Выберите ЖК'
  },
  {
    component: 'SelectedDealsBookingChessboard'
  }
];

const modalSteps = [
  STAGE_STEPS.RealtyDataStep,
  STAGE_STEPS.BookingParamsStep,
  STAGE_STEPS.BookingResultStep
];

const STAGES = {
  SelectedDealsBookingProjects: 1,
  SelectedDealsBookingChessboard: [2, 3],
  RealtyDataStep: 4,
  BookingParamsStep: 5
};

const headersParams = {
  'deals-id-booking': 'deals',
  'choice-apartment-chessboard-project': 'choice-apartment',
  'choice-apartment-project': 'choice-apartment',
  'choice-apartment-catalog': 'apartment-catalog',
  'apartment-catalog': 'apartment-catalog'
};

export const useBookingStore = defineStore('booking', () => {
  const currentStepIndex = ref<number>(0);
  const currentModalStepIndex = ref<number>(0);
  const bookingResult = ref<BookingResult | null>(null);

  const currentStep = computed(() => steps[currentStepIndex.value]);
  const currentModalStep = computed(() => modalSteps[currentModalStepIndex.value]);
  const stage = (value: keyof typeof STAGES) => STAGES[value];
  const stageCount = computed(() => Object.values(STAGES).reduce((previousValue: number, currentValue) => {
    if (Array.isArray(currentValue)) {
      return previousValue + currentValue.length;
    }

    return ++previousValue;
  }, 0));

  function setInitialValue (): void {
    currentStepIndex.value = 0;
    const chessboardStore = useChessboardStore();
    chessboardStore.resetFilter();
    chessboardStore.updateFilter('project', '');
    chessboardStore.updateFilter('building', '');
    chessboardStore.setActiveFlatId('');
    useBuildingsStore().reset();
  }

  function setModalInitialValue (): void {
    currentModalStepIndex.value = 0;
  }

  function setCurrentStep (payload: string): void {
    currentStepIndex.value = steps.findIndex(({ component }) => component === payload);
  }

  function setCurrentModalStep (payload: string): void {
    currentModalStepIndex.value = modalSteps.indexOf(payload);
  }

  function setBookingResult (payload: BookingResult): void {
    bookingResult.value = payload;
  }

  async function createObject (payload: CreateObjectPayload): Promise<number> {
    const {
      propertyId,
      type,
      bookingTypeId
    } = payload;

    if (!propertyId) {
      throw new Error('global_id is undefined');
    }
    const url = bookingApiConfig.properties();
    const createObjectData = await useApiClient().$post(url, {
      global_id: propertyId,
      type,
      booking_type_id: bookingTypeId
    });

    if (!createObjectData?.id) {
      throw new Error('object id is undefined');
    }

    return createObjectData.id;
  }

  async function createBookingByPinning ({ rootGetters }: {rootGetters: RootGetters}, payload: CreateBookingByPinningPayload): Promise<{bookingId: number}> {
    const {
      role
    } = rootGetters;
    const url = bookingApiConfig.createBooking(role);
    const { data } = await useApiClient().post(url, payload);

    return { bookingId: data.id };
  }

  async function createFreeBookingByPinning (payload: CreateFreeBookingByPinningPayload): Promise<void> {
    const $route = useIsomorphicRoute();

    const url = bookingApiConfig.freeBooking();
    await useApiClient().post(url, payload, {
      headers: {
        'X-Page-URL': headersParams[$route.value.name as keyof typeof headersParams] ?? ''
      }
    });
  }

  async function bookRealty (payload: BookRealtyPayload): Promise<BookRealtyResponse> {
    const $route = useIsomorphicRoute();
    const url = bookingApiConfig.bookRealty();

    return useApiClient().$patch(url, payload, {
      headers: {
        'X-Page-URL': headersParams[$route.value.name as keyof typeof headersParams] ?? ''
      }
    });
  }

  async function unbookingProperty (payload: {bookingId: number}) {
    const url = bookingApiConfig.unbookingProperty();
    await useApiClient().$patch(url, payload);
  }

  const decoratedApiActionsRethrow = decorateApiActions({
    createObject,
    createBookingByPinning,
    createFreeBookingByPinning,
    bookRealty,
    unbookingProperty
  }, 'store/deal/booking', true);

  return {
    currentStepIndex,
    currentModalStepIndex,
    bookingResult,
    currentStep,
    currentModalStep,
    stage,
    stageCount,
    setInitialValue,
    setModalInitialValue,
    setCurrentStep,
    setCurrentModalStep,
    setBookingResult,
    ...decoratedApiActionsRethrow
  };
});
