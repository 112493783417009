import { ref } from 'vue';
import { defineStore } from 'pinia';
import {
  decorateApiActions,
  notifyDecorate
} from '@@/shared/utilites/logging';
import { useApiClient } from '@/plugins/axios';
import { documentsApiConfig } from '@/config/api/documents';

interface GetDocumentsPayload {
  slug: string;
  bookingId: number;
}

interface DeleteDocumentPayload {
  ids: Array<string>;
  bookingId: number;
  documentId: number;
}

interface UploadDocumentsPayload {
  id: number;
  bookingId: number;
  payload: FormData;
}

interface Attachment {
  id: string;
  name: string;
}

interface Field {
    id: number;
    label: string;
    required: boolean;
    attachments: Array<Attachment>;
}

interface Document {
  title: string;
  description: string;
  label: string;
  hint: Record<string, string>;
  fields: Array<Field>;
}

export const useDocumentsStore = defineStore('documents', () => {
  const documents = ref<Array<Document>>([]);

  const setDocumentsList = (payload: Array<Document>) => {
    documents.value = payload;
  };

  const getDocuments = async ({ slug, bookingId }: GetDocumentsPayload) => {
    const url = documentsApiConfig.getQuizDocuments(slug, bookingId);
    const { data } = await useApiClient().get(url);
    setDocumentsList(data);
  };

  const sendPackageDocuments = notifyDecorate(async (bookingId: number) => {
    const url = documentsApiConfig.sendPackageDocuments(bookingId);
    await useApiClient().$post(url);
  }, 'sendPackageDocuments');

  const uploadDocuments = notifyDecorate(async ({
    bookingId,
    id,
    payload
  }: UploadDocumentsPayload) => {
    const url = documentsApiConfig.uploadDocuments();
    const { data } = await useApiClient().post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        bookingId,
        documentId: id
      }
    });

    return data;
  }, 'uploadDocuments', true);

  const deleteDocument = async (payload: DeleteDocumentPayload) => {
    const {
      ids,
      bookingId,
      documentId
    } = payload;
    const url = documentsApiConfig.deleteDocument();
    await useApiClient().patch(url, {
      file_ids: ids,
      booking_id: bookingId,
      document_id: documentId
    });
  };

  const decoratedApiActions = decorateApiActions({
    getDocuments,
    deleteDocument
  }, 'store/deal/documents', true);

  return {
    documents,
    sendPackageDocuments,
    uploadDocuments,
    ...decoratedApiActions
  };
});
