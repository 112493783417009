import { defineStore } from 'pinia';
import {
  computed,
  ref
} from 'vue';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useGlobalStore } from '../global';
import type {
  Act
} from '@/types/Acts';
import type {
  PageInfo
} from '@/types/PageInfo';

import {
  ActsOrdering,
  ActsRequestParams
} from '@/store/acts/types';
import { accountApiConfig } from '@/config/api/account';
import { useAxios } from '@/plugins/app-context';

interface ActsResponse {
  count: number;
  'page_info': PageInfo;
  result: Array<Act>;
}

export type ActsList = Array<Act>
export type PagedActs = Array<ActsList>

const orderingDateCreate = [
  {
    label: 'По убыванию даты создания',
    value: '-created_at'
  },
  {
    label: 'По возрастанию даты создания',
    value: 'created_at'
  }
];

export const useActsStore = defineStore('acts', () => {
  const pagedActs = ref<PagedActs>([]);
  const acts = computed(() => pagedActs.value.flat(1));
  const count = ref(0);
  const pageInfo = ref<null | PageInfo>(null);
  const ordering = ref<null | ActsOrdering>(null);
  const search = ref<null | string>(null);

  const getUserRole = (): string => {
    const role = useGlobalStore().role;

    if (role == null) {
      throw new Error('User role was null');
    }

    return role;
  };

  async function getActs (params: ActsRequestParams, append = false) {
    const { documents } = accountApiConfig;
    const url = documents.acts(getUserRole());

    const response = await useAxios()
      .$get<ActsResponse>(url, { params });

    if (!append) {
      pagedActs.value.splice(0);
    }

    if (response.result.length) {
      pagedActs.value.push(response.result);
    }

    count.value = response.count;
    pageInfo.value = response.page_info;

    return response;
  }

  async function getSpecs () {
    // пока так, будет пинать бэк чтобы делали спеки
    ordering.value = orderingDateCreate;
  }

  const decoratedApiActions = decorateApiActions({
    getActs,
    getSpecs
  }, 'acts/acts.ts');

  const decoratedApiActionsRethrow = decorateApiActions({
    getActsRethrow: getActs
  }, 'acts/acts.ts', true);

  return {
    acts,
    pagedActs,
    count,
    pageInfo,
    ordering,
    search,
    ...decoratedApiActions,
    ...decoratedApiActionsRethrow
  };
});
