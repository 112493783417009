const surneyApi = {
  surney: {
    getQuiz: (slug: string) => `/api/agents/questionnaire/${ slug }`,
    sendQuestion: (questionId: number) => `/api/agents/questionnaire/${ questionId }`,
    finishedQuiz: (slug: string) => `/api/agents/questionnaire/${ slug }/finish`
  }
};

export default surneyApi;

export const surneyApiConfig = surneyApi.surney;
