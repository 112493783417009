import { ref } from 'vue';
import { defineStore } from 'pinia';
import {
  decorateApiActions
} from '@@/shared/utilites/logging';
import { PropertyTypeSlug } from '@/types/Deal';

export interface Commercial {
  id: string;
  pk: string;
  article: string;
  number: string;
  area: number;
  price: number;
  hidePrice: false;
  originalPrice: number;
  fullFinalPrice: number;
  project: {
    name: string;
    slug: string;
    city: {
      name: string;
      slug: string;
    };
  };
  floor: {
    number: number;
  };
  plan: string;
  type: PropertyTypeSlug;
}

const mockData = {
  id: 'R2xvYmFsQ29tbWVyY2lhbFNwYWNlVHlwZToxMzA1MjkzMw==',
  pk: '13052933',
  article: '',
  number: '21н',
  area: 28.2,
  price: 4790000,
  hidePrice: false,
  originalPrice: 4790000,
  fullFinalPrice: 4790000,
  commercialPlan: '',
  mapHover: '',
  purposes: [],
  miniPlanPoint: {
    ppoi: '52,70',
    miniplanpointangleSet: [
      {
        angle: 120
      }
    ]
  },
  project: {
    name: 'Страна.Энтузиастов',
    slug: 'strana-entuziastov',
    deadline: '2025-10-20',
    city: {
      name: 'Екатеринбург'
    },
    metro: {
      name: 'Центр'
    },
    transportTime: 20,
    transport: {
      name: 'автомобиль',
      iconContent: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M14.9 15.6667H5.1V16.3333C5.1 16.5101 5.02625 16.6797 4.89497 16.8047C4.7637 16.9298 4.58565 17 4.4 17H3.7C3.51435 17 3.3363 16.9298 3.20503 16.8047C3.07375 16.6797 3 16.5101 3 16.3333V10.3333L4.7591 5.86533C4.85915 5.61113 5.0383 5.39207 5.27259 5.23744C5.50688 5.0828 5.78515 4.99997 6.0702 5H13.9298C14.2149 4.99997 14.4931 5.0828 14.7274 5.23744C14.9617 5.39207 15.1408 5.61113 15.2409 5.86533L17 10.3333V16.3333C17 16.5101 16.9263 16.6797 16.795 16.8047C16.6637 16.9298 16.4857 17 16.3 17H15.6C15.4143 17 15.2363 16.9298 15.105 16.8047C14.9737 16.6797 14.9 16.5101 14.9 16.3333V15.6667ZM4.4952 10.3333H15.5048L13.9298 6.33333H6.0702L4.4952 10.3333ZM6.15 13.6667C6.42848 13.6667 6.69555 13.5613 6.89246 13.3738C7.08938 13.1862 7.2 12.9319 7.2 12.6667C7.2 12.4015 7.08938 12.1471 6.89246 11.9596C6.69555 11.772 6.42848 11.6667 6.15 11.6667C5.87152 11.6667 5.60445 11.772 5.40754 11.9596C5.21062 12.1471 5.1 12.4015 5.1 12.6667C5.1 12.9319 5.21062 13.1862 5.40754 13.3738C5.60445 13.5613 5.87152 13.6667 6.15 13.6667ZM13.85 13.6667C14.1285 13.6667 14.3955 13.5613 14.5925 13.3738C14.7894 13.1862 14.9 12.9319 14.9 12.6667C14.9 12.4015 14.7894 12.1471 14.5925 11.9596C14.3955 11.772 14.1285 11.6667 13.85 11.6667C13.5715 11.6667 13.3045 11.772 13.1075 11.9596C12.9106 12.1471 12.8 12.4015 12.8 12.6667C12.8 12.9319 12.9106 13.1862 13.1075 13.3738C13.3045 13.5613 13.5715 13.6667 13.85 13.6667Z" fill="currentColor"/>\n</svg>\n'
    },
    gallerySlides: [
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/ef36481e16685333283ef3e43ceb88bc6b292d1c.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/ef36481e16685333283ef3e43ceb88bc6b292d1c.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/bd9e3a2180243a3e25b151fab804f570f32c31dc.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/bd9e3a2180243a3e25b151fab804f570f32c31dc.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/63768f4c13c33ce28054d44946f236da397a3b2a.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/63768f4c13c33ce28054d44946f236da397a3b2a.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/4632eff9104faf70365d743cfd07e6bcda5a11f2.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/4632eff9104faf70365d743cfd07e6bcda5a11f2.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/d1a5ed1a662b4e475a15fd2f838b17ed72f9b08b.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/d1a5ed1a662b4e475a15fd2f838b17ed72f9b08b.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/e45350c533c7453d10060fff1b04a7b2e025462b.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/e45350c533c7453d10060fff1b04a7b2e025462b.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/ed52ea2fe55e0f0e1548e244ad348c446a61d7ef.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/ed52ea2fe55e0f0e1548e244ad348c446a61d7ef.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/c36e7bc40eadc486271842bd299fd61c98616a10.png',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/c36e7bc40eadc486271842bd299fd61c98616a10.png'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/dc2eb79bfa03d6625d6607c4272202ebbd246de8.png',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/dc2eb79bfa03d6625d6607c4272202ebbd246de8.png'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/8bbd9c8bade2286a927ee3dbcb413a63b4a42b1e.png',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/8bbd9c8bade2286a927ee3dbcb413a63b4a42b1e.png'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/7969d9edafb228c3502a171968c00c861fb79726.png',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/7969d9edafb228c3502a171968c00c861fb79726.png'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/bd9e3a2180243a3e25b151fab804f570f32c31dc.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/bd9e3a2180243a3e25b151fab804f570f32c31dc.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/cc6945c93e2fe3eada1ecd2c14d1d620464a65a3.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/cc6945c93e2fe3eada1ecd2c14d1d620464a65a3.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/e7bdc501bb393d273a9b7325ff339b420f728c33.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/e7bdc501bb393d273a9b7325ff339b420f728c33.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/37dc0469f3e34b2b2a4b37cafdab38a16e7a1496.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/37dc0469f3e34b2b2a4b37cafdab38a16e7a1496.jpg'
      },
      {
        imagePhoneDisplay: 'https://cdn.strana.com/public-bucket-strana/p/gallery/dde0beeea175464fb84a1ae411aab3188303c53b.jpg',
        imagePhonePreview: 'https://cdn.strana.com/public-bucket-strana/p/gallery/dde0beeea175464fb84a1ae411aab3188303c53b.jpg'
      }
    ]
  },
  building: {
    name: 'Дом Энергия КМ-1',
    nameDisplay: 'Дом Энергия',
    address: 'г. Екатеринбург, ул. Шефская, 22/1',
    miniPlan: 'https://cdn.strana.com/public-bucket-strana/b/b/mp/182b9d8c7d91af9d50466d810449638717c8b1a6.png',
    miniPlanWidth: 952,
    miniPlanHeight: 740,
    compassAzimuth: 280,
    sunAzimuth: 180,
    builtYear: 2025,
    readyQuarter: 4,
    currentLevel: 63,
    windowViewPlanLotPreview: 'https://cdn.strana.com/public-bucket-strana/b/b/wvp/be2931c41d52b6066953a1a2de2ce6b3d8f45d45.jpg',
    windowViewPlanLotDisplay: 'https://cdn.strana.com/public-bucket-strana/b/b/wvp/be2931c41d52b6066953a1a2de2ce6b3d8f45d45.jpg'
  },
  section: {
    totalFloor: null
  },
  floor: {
    number: 2
  },
  plan: 'https://cdn.strana.com/public-bucket-strana/pp/f/p/1eb520c8774efc1d24788874fe46999d66f03814.svg',
  promoEnd: null,
  type: 'COMMERCIAL',
  hasAuction: false,
  auction: null,
  windowView: {
    ppoi: '43,55',
    windowviewangleSet: [
      {
        angle: 120
      }
    ]
  },
  buildingTotalFloor: 2
};

export const useCommercialStore = defineStore('commercial', () => {
  const commercialList = ref<Array<Commercial>>([]);

  async function loadCommercial (): Promise<void> {
    // Заполняем массив моками
    commercialList.value = Array(8).fill(mockData);
  }

  return {
    commercialList,
    ...decorateApiActions({
      loadCommercial
    }, 'store/commercial.ts')
  };
});
