import {
  ref,
  computed
} from 'vue';
import { defineStore } from 'pinia';
import { pageScrollService } from '@@/shared/services/PageScrollService';
import { BURGER_MENU_ID } from '@@/shared/constants/app-ids';

export const useHeaderStore = defineStore('header', () => {
  const isOpened = ref<boolean>(false);

  const getHeaderState = computed((): boolean => isOpened.value);

  const toggleHeader = (): void => {
    isOpened.value = !isOpened.value;
    const scrollableMenu = document.getElementById(BURGER_MENU_ID);

    if (scrollableMenu) {
      if (isOpened.value) {
        pageScrollService.lock([scrollableMenu]);
      } else {
        pageScrollService.unlock([scrollableMenu]);
      }
    }
  };

  return {
    isOpened,
    getHeaderState,
    toggleHeader
  };
});
