import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { PortalTarget } from 'portal-vue';
import { provide } from 'vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheModalIframe from '@/components/layout/TheModalIframe.vue';
import TheContextMenu from '@/components/layout/TheContextMenu.vue';
import TheModal from '@/components/layout/TheModal.vue';
import { usePortalApi } from '@/plugins/api/portal';
import { useScreenPlugin } from '@/plugins/vue-screen';
import { useHeaderStore } from '@/store/header';
import { useNotificationsStore } from '@/store/notification';
import { useGlobalStore } from '@/store/global';
export default {
  name: 'Default',
  components: {
    TheModal: TheModal,
    TheContextMenu: TheContextMenu,
    TheModalIframe: TheModalIframe,
    TheFooter: TheFooter,
    TheHeader: TheHeader,
    PortalTarget: PortalTarget,
    TheBurgerMenu: function TheBurgerMenu() {
      return import('@/components/layout/TheBurgerMenu.vue');
    }
  },
  setup: function setup() {
    provide('$portalApi', usePortalApi());
    provide('$screen', useScreenPlugin());
    var globalStore = useGlobalStore();
    var googleTagManagerId = function googleTagManagerId() {
      var _a;
      return ((_a = globalStore.activeCity) === null || _a === void 0 ? void 0 : _a.name) !== 'Москва' ? 'GTM-MR9TQWJ' : 'GTM-PB4MD35';
    };
    var headerStore = useHeaderStore();
    var toggleHeader = headerStore.toggleHeader;
    return {
      notificationsStore: useNotificationsStore(),
      headerStore: headerStore,
      toggleHeader: toggleHeader,
      googleTagManagerId: googleTagManagerId
    };
  },
  data: function data() {
    return {
      height: 'unset'
    };
  },
  head: function head() {
    return {
      script: [
      // GA start
      {
        innerHTML: "\n            (function(i,s,o,g,r,a,m){i[\"GoogleAnalyticsObject\"]=r;i[r]=i[r]||function(){\n            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n            })(window,document,\"script\",\"https://www.google-analytics.com/analytics.js\",\"ga\");\n            window.ga(\"create\", \"UA-111285949-5\", \"auto\");\n            setTimeout(function(){ window.ga(\"send\", \"pageview\"); }, 1000)\n          "
      },
      // GTM start
      {
        innerHTML: "\n            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','".concat(this.googleTagManagerId, "');\n          ")
      }
      // GTM end
      ],
      noscript: [
      // GTM noscripts
      {
        innerHTML: "\n            <iframe src=\"https://www.googletagmanager.com/ns.html?id='".concat(this.googleTagManagerId, "\"\n                    height=\"0\"\n                    width=\"0\"\n                    style=\"display:none;visibility:hidden\"\n            ></iframe>\n          "),
        body: true
      }],
      __dangerouslyDisableSanitizers: ['script', 'noscript']
    };
  },
  computed: {
    notifications: function notifications() {
      return this.notificationsStore.notifications;
    },
    isOpened: function isOpened() {
      return this.headerStore.getHeaderState;
    },
    isFooter: function isFooter() {
      var _a, _b;
      return !((_b = (_a = this.$route) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.hideFooter);
    },
    isFixed: function isFixed() {
      var _a, _b, _c, _d;
      return ((_b = (_a = this.$route) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.includes('contract')) || ((_d = (_c = this.$route) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.includes('account'));
    }
  },
  watch: {
    'notifications.length': function notificationsLength() {
      this.getHeaderHeight();
    }
  },
  mounted: function mounted() {
    this.getHeaderHeight();
  },
  methods: {
    getHeaderHeight: function getHeaderHeight() {
      var _this = this;
      this.$nextTick(function () {
        var _a;
        var height = (_a = document.querySelector('header')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height;
        _this.height = height ? "".concat(height, "px") : 'unset';
      });
    }
  }
};