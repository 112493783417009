import {
  ref,
  computed
} from 'vue';
import { defineStore } from 'pinia';
import { decorateApiActions } from '@@/shared/utilites/logging';
import allCities from '../queries/allCities.graphql';
import allSocials from '../queries/allSocials.graphql';
import { useAuthStore } from './authStore';
import { GlobalCity } from './cities';
import { useApiClient } from '@/plugins/axios';
import { usePortalApi } from '@/plugins/api/portal';
import { documentsApiConfig } from '@/config/api/documents';

interface InterfaceFile {
  aws: string;
}

interface ReglamentResponse {
  slug?: string;
  file: InterfaceFile;
  text: string;
}
interface Reglament {
  data: ReglamentResponse;
  isFetched: boolean;
}

interface Social {
  id: string;
  order: number;
  link: string;
  iconContent: string;
  coloredIconContent: string;
}

type AllSocials = Array<Social>

export const useGlobalStore = defineStore('global', () => {
  const activeCity = ref<GlobalCity|null>(null);
  const citiesGlobal = ref<Array<GlobalCity>>([]);
  const reglament = ref<Reglament>({
    data: {
      file: {
        aws: ''
      },
      text: ''
    },
    isFetched: false
  });
  const social = ref<AllSocials>([]);

  const getReglamentLink = computed(() => reglament.value?.data?.file?.aws || '');
  const getReglamentText = computed(() => reglament.value?.data?.text || '');
  const getActiveCity = computed(() => activeCity.value);
  const getGlobalCities = computed((): Array<GlobalCity> => citiesGlobal.value);

  const setActiveCity = (payload: GlobalCity) => {
    activeCity.value = payload;
  };

  const setReglament = (payload: ReglamentResponse) => {
    reglament.value.data = payload;
    reglament.value.isFetched = true;
  };

  const setSocial = (payload: AllSocials) => {
    social.value = [...payload];
  };

  const setCities = (payload: Array<GlobalCity>) => {
    citiesGlobal.value = payload;
  };

  const role = computed(() => {
    const auth = useAuthStore();
    const strategy = auth.strategy;

    if (strategy === 'represes') {
      return 'repres';
    }

    if (strategy === 'agents') {
      return 'agent';
    }

    return null;
  });

  const getAllCities = async () => {
    const portalApi = usePortalApi();

    return portalApi.$post('/graphql/', {
      query: allCities.loc?.source.body
    });
  };

  const getAllSocials = async () => {
    const portalApi = usePortalApi();

    return portalApi.$post('/graphql/', {
      query: allSocials.loc?.source.body
    });
  };

  const getReglament = async () => {
    if (!reglament.value.isFetched) {
      const url = documentsApiConfig.detail('reglament');
      const response = await useApiClient().$get(url);
      setReglament(response);

      return response;
    }

    return reglament.value.data;
  };

  const globalInit = async () => {
    const [citiesRes, socialRes] = await Promise.all([
      getAllCities(),
      getAllSocials()
    ]);

    if (citiesRes.data?.currentCity) {
      setActiveCity(citiesRes.data.currentCity);
    }

    const cities = citiesRes?.data?.allCities?.edges?.length
      ? citiesRes.data.allCities.edges.map((item: {node: GlobalCity}) => item.node)
      : [];
    const socialList = socialRes?.data?.allSocials
      ? socialRes.data.allSocials.sort((a: Social, b: Social) => a.order - b.order)
      : [];

    setCities(cities);
    setSocial(socialList);
  };

  const decoratedApiActions = decorateApiActions({
    globalInit,
    getAllCities,
    getAllSocials,
    getReglament
  }, 'store/global.ts', true);

  return {
    activeCity,
    citiesGlobal,
    reglament,
    social,
    getReglamentLink,
    getReglamentText,
    getActiveCity,
    getGlobalCities,
    role,
    setActiveCity,
    setReglament,
    setSocial,
    setCities,
    ...decoratedApiActions
  };
});
