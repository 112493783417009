import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { mapState } from 'vuex';
import TheFooter from '@/components/layout/TheFooter.vue';
import { useHeaderStore } from '@/store/header';
export default {
  name: 'LayoutLanding',
  components: {
    TheFooter: TheFooter,
    TheBurgerMenu: function TheBurgerMenu() {
      return import('@/components/layout/TheBurgerMenu.vue');
    }
  },
  setup: function setup() {
    var headerStore = useHeaderStore();
    var _useHeaderStore = useHeaderStore(),
      toggleHeader = _useHeaderStore.toggleHeader;
    return {
      headerStore: headerStore,
      toggleHeader: toggleHeader
    };
  },
  data: function data() {
    return {
      height: 'unset'
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('notification', ['notifications'])), {}, {
    isOpened: function isOpened() {
      return this.headerStore.getHeaderState;
    },
    isFixed: function isFixed() {
      var _a, _b, _c, _d;
      return ((_b = (_a = this.$route) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.includes('contract')) || ((_d = (_c = this.$route) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.includes('account'));
    }
  }),
  watch: {
    'notifications.length': function notificationsLength() {
      this.getHeaderHeight();
    }
  },
  mounted: function mounted() {
    this.getHeaderHeight();
  },
  methods: {
    getHeaderHeight: function getHeaderHeight() {
      var _this = this;
      this.$nextTick(function () {
        var _a;
        var height = (_a = document.querySelector('header')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height;
        _this.height = height ? "".concat(height, "px") : 'unset';
      });
    }
  }
};