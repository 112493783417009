import { defineStore } from 'pinia';

import { useGlobalStore } from './global';
import {
  PinStatus,
  ClientResponse
} from '@/types/Client';
import {
  getUserBookings,
  UserBookingRequest
} from '@/components/common/modals/ModalCheckUniqueness/api';

interface CheckedUserResponse {
  agencyId: number;
  agentId: number;
  canDispute: false;
  id: number;
  requested: Date;
  status: PinStatus;
  userId: number;
  button: {
    text: string;
    description: string;
  } | null;
}

interface PinUserResponse {
  'agent_id': number;
  'agency_id': number;
  id: number;
  bookingId: number;
}

interface PinUserRequest {
  name: string;
  surname: string;
  patronymic: string;
  email: string | null;
  phone: string;
  'check_id': number;
  'user_id': number;
  'active_projects': Array<number>;
  'agency_contact': string;
  'assignation_comment': string;
}

interface State {
  client: ClientResponse | Record<string, never>;
  userData: CheckedUserResponse | null;
  userBooking: Array<UserBookingRequest>;
}

const getDefaultState = (): State => ({
  client: {},
  userData: null,
  userBooking: []
});

export const useClientStore = defineStore('client', {
  state: (): State => getDefaultState(),

  actions: {
    /**
     * Получение клиента по ID
     **/
    async getClient (id: number): Promise<void> {
      try {
        const { role } = useGlobalStore();
        const {
          clientDetail
        } = this.$api.account.dependentRole;
        const url = clientDetail('users', role, id);
        const {
          data
        } = await this.$axios.get<ClientResponse>(url);

        this.client = data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: client.ts ~ getClient ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    /**
     * Проверка клиента на уникальность
     **/
    async checkClientUnique (phone: string): Promise<void> {
      try {
        const { role } = useGlobalStore();
        const {
          check
        } = this.$api.account.dependentRole;
        const url = check('users', role);
        const {
          data
        } = await this.$axios.post<CheckedUserResponse>(url, {
          phone
        });

        this.userData = data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: client.ts ~ checkClientUnique ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    /**
     * Закрепление клиента
     *  @param payload
     **/
    async pinClient (payload: PinUserRequest): Promise<PinUserResponse> {
      try {
        const { role } = useGlobalStore();
        const {
          pin
        } = this.$api.users;
        const url = pin(role);

        const {
          data
        } = await this.$axios.post<PinUserResponse>(url, payload);

        return data;
      } catch (error: unknown) {
        console.log('🚀 ~ file: client.ts ~ pinClient ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    /**
     * Оспаривание закрепление клиента
     **/
    async checkStartDispute (payload: {
      userId: number;
      comment: string;
    }): Promise<void> {
      try {
        const { role } = useGlobalStore();
        const {
          checkDispute
        } = this.$api.account.dependentRole;
        const url = checkDispute('users', role);

        await this.$axios.patch(url, payload);
      } catch (error: unknown) {
        console.log('🚀 ~ file: client.ts ~ checkStartDispute ~ error', error);
        this.$sentry.captureException(error);
        throw error;
      }
    },

    reset (): void {
      this.$reset();
    },

    async getBookingByUser (userId: number): Promise<void> {
      this.userBooking = await getUserBookings(userId);
    }
  }
});
