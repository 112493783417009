const documentsApi = {
  documents: {
    detail: (documentSlug: string) => `/api/documents/slug/${ documentSlug }`,
    getQuizDocuments: (slug: string, bookingId: number) => `/api/agents/questionnaire/${ slug }/groups_of_documents/${ bookingId }`,
    sendPackageDocuments: (bookingId: number) => `/api/agents/questionnaire/${ bookingId }/send`,
    uploadDocuments: () => '/api/v2/documents/upload-documents',
    deleteDocument: () => '/api/documents/delete-documents',
    getDocument: (role: string, id: number) => `/api/agencies/${ role }/agreements/${ id }/get_document`
  }
};

export default documentsApi;

export const documentsApiConfig = documentsApi.documents;
