const bookingApi = {
  booking: {
    createBooking: (role: string) => `api/users/${ role }/create_booking`,
    freeBooking: () => 'api/dsl/free_booking',
    bookRealty: () => 'api/properties/bind',
    properties: () => 'api/properties',
    unbookingProperty: () => 'api/properties/unbind'
  }
};

export default bookingApi;

export const bookingApiConfig = bookingApi.booking;
