import { defineStore } from 'pinia';
import { saveManyCitiesOfSale } from '@@/shared/composables/cityOfSale';
import {
  FLAGS,
  useFeatureFlagsStore
} from '../featureFlags';
import type {
  CitySelectType
} from '@/types/Cities';

import { useMeetingsStore } from '@/store/meetings';
import { useCitiesStore } from '@/store/cities';
import { useCalendarStore } from '@/store/calendar';

export interface CalendarFilters {
  /* FIXME фильтры должны быть в сторе, сделать в задаче по фильтрам */
  type: 'event' | 'meeting'; // тип события календаря (встреча/мероприятие)
  formatType: 'online' | 'offline'; // формат события календаря (онлайн/ офлайн)
  dateStart: Date; // дата начала сортировки мероприятий и встреч календаря
  dateEnd: Date; // дата конца сортировки мероприятий и встреч календаря

  showOnlyRecordedEvents: boolean; // для получения списка мероприятий календаря, на которых записан текущий агент
  showOnlyNotRecordedMeetings: boolean; // для получения списка мероприятий календаря, на которых не записан текущий агент
  eventCity?: Array<string>; // фильтр мероприятий календаря по городам (несколько значений)

  meetingStatus: Array<string>; // фильтрация встреч календаря по статусам (несколько значений)
  meetingClient: string; // фильтрация встреч календаря по клиенту (поиск по почте, номеру, имени, фамилии или отчеству)
  bookingId: number; // фильтр встреч по сделке
}

interface SpecsItem {
  value?: string;
  label: string;
}

interface Specs {
  type: Array<SpecsItem>;
  formatType: Array<SpecsItem>;
  meetingStatus: Array<SpecsItem>;
  eventCity: Array<SpecsItem>;
  showOnlyRecordedEvents: Array<SpecsItem>;
}

interface State {
  filters?: CalendarFilters;
}

export const useCalendarFiltersStore = defineStore('calendarFilters', {
  state: (): State => ({
    filters: undefined
  }),

  getters: {
    specs () {
      const specs: Specs = {
        type: [ // Тип календаря
          {
            value: 'all',
            label: 'Все'
          },
          {
            value: 'meeting',
            label: 'Встречи'
          },
          {
            value: 'event',
            label: 'Мероприятия'
          }
        ],

        formatType: [ // Тип события
          {
            value: 'all',
            label: 'Любой'
          },
          {
            value: 'online',
            label: 'Онлайн'
          },
          {
            value: 'offline',
            label: 'Оффлайн'
          }
        ],
        meetingStatus: [ // Статус встречи
        ],

        eventCity: [ // Город мероприятия
        ],
        showOnlyRecordedEvents: [ // Участник мероприятия
          {
            value: 'all',
            label: 'Не важно'
          },
          {
            value: 'true',
            label: 'Участвую'
          },
          {
            value: 'false',
            label: 'Не участвую'
          }
        ]
      };
      const { meetings } = useMeetingsStore();
      const citiesStore = useCitiesStore();
      const meetingStatuses = meetings?.statuses;
      const cities = citiesStore.cities?.map(({ text, citySlug }: CitySelectType) => ({
        label: text,
        value: citySlug
      }));

      specs.meetingStatus = [
        ...specs.meetingStatus,
        ...meetingStatuses
      ];

      specs.eventCity = [
        ...specs.eventCity,
        ...cities
      ];

      return specs;
    }
  },

  actions: {
    async setFilters (payload: CalendarFilters): Promise<void> {
      this.filters = payload;
      const { getEvents } = useCalendarStore();
      await getEvents();
    },

    async addFilters (payload: CalendarFilters): Promise<void> {
      const filters: CalendarFilters = {
        ...this.filters,
        ...payload
      };

      const featureFlagsStore = useFeatureFlagsStore();
      const cityOfSaleFeatureEnabled = featureFlagsStore.isEnabledFlag(FLAGS.cityOfSale);

      if (cityOfSaleFeatureEnabled) {
        const citiesStore = useCitiesStore();
        const found = filters.eventCity ? citiesStore.getCitiesBySlugs(filters.eventCity) : undefined;

        if (found?.length) {
          saveManyCitiesOfSale(found);
        }
      }
      this.filters = filters;
    },

    async resetFilters (): Promise<void> {
      this.filters = undefined;
      const { getEvents } = useCalendarStore();
      await getEvents();
    }
  }
});
