// Стор для feature flags
// import { useFeatureFlagsStore, FLAGS } from '@/store/featureFlags';
// При переходе между страницами с бэка запрашиваем список флагов
// В компоненте используем геттер
// setup () {
//   const featureFlagsStore = useFeatureFlagsStore();
//   const { isEnabledFlag } = storeToRefs(featureFlagsStore);
//   return {
//     isEnabledFlag
//   };
// },
// Использовать
// v-if = isEnabledFlag(FLAGS[slug])

import { decorateApiActions } from '@@/shared/utilites/logging';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAxios } from '@/plugins/app-context';

type Flags = Record<string, boolean>;

/* список флагов которые используются в проекте */
export const FLAGS = {
  manager: 'strana_lk-4219', // Связаться с менеджером
  meetingProject: 'strana_lk-4450', // поле проект активное
  bookingButtons: 'strana_lk-4982_f', // кнопки в шахматке - забронировать платно или бесплатно
  expiredDateStatus: 'del002-18_f', // дата понижения статуса ПЛ
  redesign: 'strana_lk-4441', // Редизайн
  support: 'strana_lk-4438_f', // Редизайн
  redesignDeals: 'strana_lk-4441_deals', // Редизайн страницы сделок
  cityOfSale: 'strana_lk-4429_f', // Город продаж
  catalogNewFilters: 'strana_lk-4771' // новые фильтры в каталоге
} as const;

type TFlags = typeof FLAGS
type TFlagsValues = TFlags[keyof TFlags]
const flags: Array<TFlagsValues> = Object.values(FLAGS);

export const useFeatureFlagsStore = defineStore('featureFlagsBroker', () => {
  const featureFlags = ref<Flags>({});

  async function getFlags (): Promise<void> {
    const url: string = 'api/feature-flags';

    if (!flags.length) {
      return;
    }

    const {
      data
    } = await useAxios().get(url, {
      params: {
        feature_flag: flags
      }
    });

    featureFlags.value = {
      ...data
    };
  }

  /**
   * Использовать вместе с константой FLAGS из этого же файла
   */
  function isEnabledFlag (flag: TFlagsValues): boolean {
    return Boolean(featureFlags.value[flag]);
  }

  return {
    featureFlags,
    ...decorateApiActions({ getFlags }, 'featureFlags.ts'),
    isEnabledFlag,
    isRedesignEnabled: () => isEnabledFlag(FLAGS.redesign),
    isExpiredDateStatusEnabled: () => isEnabledFlag(FLAGS.expiredDateStatus),
    hasCatalogNewFilters: () => isEnabledFlag(FLAGS.catalogNewFilters)
  };
});
