import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import { decorateApiActions } from '@@/shared/utilites/logging';
import { useAxios } from '@/plugins/app-context';

export interface AddFavoriteRequest {
  area: number;
  floor: number;
  number: number;
  profitbaseId: number;
  article: string;
  citySlug: string;
  projectSlug: string;
}

export const usePropertyStore = defineStore('property', () => {
  const currentProfitBaseId = ref<number>();

  function setCurrentProfitBaseId (id: number): void {
    currentProfitBaseId.value = id;
  }

  function resetProfitBaseId (): void {
    currentProfitBaseId.value = undefined;
  }

  async function getPropertyFavorite (profitbaseId: number): Promise<boolean | undefined> {
    const url = `/api/properties/article/like/${ profitbaseId }`;
    const {
      data
    } = await useAxios().get<boolean>(url);

    return data;
  }

  async function addPropertyFavorite (payload: AddFavoriteRequest): Promise<void> {
    const url = '/api/properties/article/like';
    await useAxios().post<boolean>(url, {
      ...payload
    });
  }

  async function deletePropertyFavorite (profitbaseId: number): Promise<void> {
    const url = `/api/properties/article/like/${ profitbaseId }`;
    await useAxios().delete<boolean>(url);
  }

  return {
    currentProfitBaseId,
    setCurrentProfitBaseId,
    resetProfitBaseId,
    ...decorateApiActions({
      deletePropertyFavorite,
      getPropertyFavorite,
      addPropertyFavorite
    }, 'store/commercial.ts')
  };
});
